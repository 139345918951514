<script lang="ts" setup>
  import type { Profile } from '~/entities/profile'

  import { listUserProfilesPage } from '~/features/profiles'

  import DynamicSearchItem from './DynamicSearchItem.vue'

  const props = defineProps<{
    searchQuery?: string
    searchPage?: boolean
    searchInputInFocus: boolean
  }>()
  const emits = defineEmits<{
    (e: 'select-item'): void
  }>()
  const profiles = ref<Profile[]>([])
  const dynamicSearch = ref()
  const showDynamicSearch = ref(false)
  watch(
    () => [props.searchQuery, props.searchInputInFocus],
    () => {
      if (!props.searchQuery || props.searchQuery.length < 2 || !props.searchInputInFocus) {
        showDynamicSearch.value = false
      } else {
        listUserProfilesPage({
          page: {
            number: 1,
            size: 10
          },
          filter: {
            query: props.searchQuery
          }
        }).then((result) => {
          profiles.value = result.userProfiles
          if (profiles.value.length) {
            showDynamicSearch.value = true
          } else {
            showDynamicSearch.value = false
          }
        })
      }
    }
  )
  onClickOutside(dynamicSearch, () => {
    showDynamicSearch.value = false
  })

  const handleSelectItem = () => {
    showDynamicSearch.value = false
    emits('select-item')
  }
</script>

<template>
  <div
    v-if="showDynamicSearch"
    ref="dynamicSearch"
    :class="['dynamic-search__container', { 'search-page': searchPage }]"
  >
    <div class="dynamic-search__wrapper">
      <DynamicSearchItem
        v-for="profile in profiles"
        :key="profile.id"
        :profile="profile"
        @click="handleSelectItem"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .dynamic-search {
    &__container {
      position: absolute;
      left: 0;
      width: 100%;
      min-height: 100px;
      padding: 12px 6px;
      background-color: $purple;
      border-radius: 0 0 12px 12px;

      max-height: 252px;
      box-sizing: border-box;

      z-index: 5;
      &.search-page {
        border-radius: 12px;
        border: 1px solid $purple-light;
        border-top: none;
      }
    }
    &__wrapper {
      overflow-y: auto;
      @include custom-scrollbar;
      @include flex-container(column, flex-start, flex-start);
      gap: 10px;
      max-height: 240px;
    }
  }
</style>
